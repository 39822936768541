import { useTranslation } from "react-i18next";

import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { DownloadReportFormat } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { ActionSheet } from "@kraaft/ui";

interface FormatSelectionSheetProps {
  reportTemplate: ReportTemplate;

  onFormatSelected: (
    template: ReportTemplate,
    format: DownloadReportFormat,
  ) => void;
}

export const FormatSelectionSheet =
  ActionSheet().create<FormatSelectionSheetProps>(
    ({ ActionSheetItem, ActionSheetContent }) =>
      ({ onFormatSelected, reportTemplate }) => {
        const { t } = useTranslation();

        return (
          <ActionSheetContent title={t("reportTemplate.useFormat")}>
            {reportTemplate?.format === "xlsx" ? (
              <ActionSheetItem
                key="EXCEL"
                icon="excel-icon"
                label="EXCEL"
                onClick={() => onFormatSelected(reportTemplate, "xlsx")}
              />
            ) : (
              <ActionSheetItem
                key="WORD"
                icon="word-icon"
                label="WORD"
                onClick={() => onFormatSelected(reportTemplate, "docx")}
              />
            )}
            <ActionSheetItem
              key="PDF"
              icon="pdf-icon"
              label="PDF"
              onClick={() => onFormatSelected(reportTemplate, "pdf")}
            />
          </ActionSheetContent>
        );
      },
  );
