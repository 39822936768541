import { put } from "typed-redux-saga/macro";

import { ModernFileHelper } from "@kraaft/shared/core/modules/file/file";
import * as actionsFileBucket from "@kraaft/shared/core/modules/fileBucket/fileBucketActions";
import { fillDirectoryWithFile } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { sendFileMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { generateContextId } from "@kraaft/shared/core/utils";

// eslint-disable-next-line complexity
export function* uploadFilesSaga(
  action: ReturnType<typeof roomActions.uploadFilesRequest>,
) {
  const { files, context } = action.payload;

  try {
    switch (context.type) {
      case "shareExtension":
      case "share":
      case "room": {
        const { type: _, ...cleanContext } = context;

        for (const file of files) {
          const coordinates = ModernFileHelper.isMedia(file)
            ? file.coordinates
            : undefined;

          yield* put(
            sendFileMessage({
              file,
              coords: coordinates,
              caption: ModernFileHelper.isMedia(file)
                ? file.caption
                : undefined,
              optimisticId: file.optimisticId,
              ...cleanContext,
            }),
          );
        }

        break;
      }
      case "modularFolder": {
        const { type: _, roomId, modularFolderId, ...cleanContext } = context;

        yield* put(
          BatchActions({
            actions: files.map((file) =>
              OfflineModularFolderActions.addAttachment({
                roomId,
                id: modularFolderId,
                columnKey: cleanContext.columnKey,
                files: [file],
              }),
            ),
          }),
        );
        break;
      }

      case "directory":
        yield* put(fillDirectoryWithFile({ files, ...context }));
        break;

      default:
        break;
    }
    yield* put(
      actionsFileBucket.removeFilesSenderBucket({
        id: generateContextId(action.payload.context),
        emptyAll: true,
      }),
    );
  } catch (error) {
    yield* put(roomActions.uploadFilesFailure(error));
  }
}
