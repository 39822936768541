import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { uuid } from "@kraaft/helper-functions";
import { DroppableItem } from "@kraaft/shared/components/droppable";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { filePicker } from "@kraaft/shared/core/modules/file/filePicker";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { selectModularFolderTitle } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { uploadFilesRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoomIsExternal } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import {
  DroppableModularTableColumn,
  ModularDropHelpers,
} from "@kraaft/shared/core/utils/useModularDrop/types";

interface Props {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
}

export function useModularDrop({
  roomId,
  schemaId,
  modularFolderId,
}: Props): ModularDropHelpers {
  const dispatch = useDispatch();
  const isRoomExternal = useSelector(selectRoomIsExternal(roomId));

  const schemaName = useSelector(selectSchemaName(schemaId));
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const onDrop = useCallback(
    (column?: DroppableModularTableColumn) => {
      return (item: DroppableItem) => {
        const { messageId } = item;

        trackEvent({
          eventName: "Attach To Object Record",
          room_id: roomId,
          schema_id: schemaId,
          is_shared: isRoomExternal,
          schema_name: schemaName ?? "",
          record_title: modularFolderTitle,
          from: "message drag&drop",
          element_count: 1,
          media_extension: "unknown",
        });

        dispatch(
          OfflineModularFolderActions.addAttachmentFromMessage({
            id: modularFolderId,
            roomId,
            messageId,
            columnKey: column?.key,
          }),
        );
      };
    },
    [
      dispatch,
      isRoomExternal,
      modularFolderId,
      modularFolderTitle,
      roomId,
      schemaId,
      schemaName,
    ],
  );

  const handlePickedFiles = useCallback(
    (
      column: DroppableModularTableColumn | undefined,
      files: Array<ModernFile<LocalPath>>,
    ) => {
      const context: UploadAttachmentContext = {
        type: "modularFolder",
        columnKey: column?.key,
        roomId,
        schemaId,
        modularFolderId,
      };

      return dispatch(
        uploadFilesRequest({
          context,
          files: files.map((file) => ({ ...file, optimisticId: uuid() })),
        }),
      );
    },
    [dispatch, modularFolderId, roomId, schemaId],
  );

  const handlePickImages = useCallback(
    (column?: DroppableModularTableColumn) => {
      return async () => {
        const files = await filePicker.pickImages();

        if (!files || files.length === 0) {
          return;
        }
        handlePickedFiles(column, files);
      };
    },
    [handlePickedFiles],
  );

  const handlePickDocuments = useCallback(
    (column?: DroppableModularTableColumn) => {
      return async () => {
        const files = await filePicker.pickDocuments();

        if (!files || files.length === 0) {
          return;
        }
        handlePickedFiles(column, files);
      };
    },
    [handlePickedFiles],
  );

  const handleDroppedFiles = useCallback(
    (column?: DroppableModularTableColumn) =>
      (files: Array<ModernFile<LocalPath>>) => {
        const context: UploadAttachmentContext = {
          type: "modularFolder",
          columnKey: column?.key,
          roomId,
          schemaId,
          modularFolderId,
        };

        return dispatch(
          uploadFilesRequest({
            context,
            files: files.map((file) => ({ ...file, optimisticId: uuid() })),
          }),
        );
      },
    [dispatch, modularFolderId, roomId, schemaId],
  );

  const noopnoop = useCallback(() => noop, []);

  return {
    onDrop,
    onDropFiles: handleDroppedFiles,
    onPickDocuments: handlePickDocuments,
    onPickImages: handlePickImages,
    openCamera: noopnoop,
  };
}
