import { network } from "@kraaft/shared/core/modules/network/network.provider";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { NetworkError } from "@kraaft/shared/core/services/firebase/networkError";
import { NamespacedTaskManager } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/taskStore";

import { trackEvent } from "../../utils/tracking/trackEvent";

export const taskManager = new NamespacedTaskManager(storage, (error) => {
  const isNetworkError = error instanceof NetworkError;

  if (isNetworkError) {
    return "halt";
  }
  if (error instanceof HttpError) {
    return "skip";
  }
  return "skip";
});

// Retry strategy
network.onStateChange.register((state) => {
  if (state.isInternetReachable) {
    trackEvent({
      eventName: "Offline",
      level: "info",
      log: "Network state change",
      data: {
        isInternetReachable: state.isInternetReachable,
        networkType: state.networkType,
      },
    });
    console.log(
      "Retrying on network state change",
      state.isInternetReachable,
      state.networkType,
    );
    taskManager.execute().catch(console.error);
  }
});
