import React from "react";

import { useDownloadReport } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/useDownloadReport";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";

import { ModularFolderContextMenuSheet } from "./modularFolderContextMenu.sheet";

type UseModularFolderContextMenuProps = {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  anchorRef: React.RefObject<HTMLElement>;
};

export function useModularFolderContextMenu(
  props: UseModularFolderContextMenuProps,
) {
  const { roomId, schemaId, modularFolderId, anchorRef } = props;

  const { downloadReport } = useDownloadReport({
    schemaId,
    modularFolderId,
  });

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId,
      callback: downloadReport,
      anchorRef,
      allowPDF: true,
    });

  const { element: contextMenuSheet, open: openContextMenu } =
    ModularFolderContextMenuSheet.use({
      roomId,
      schemaId,
      modularFolderId,
      anchor: anchorRef,
      openReportTemplateSelection,
    });

  const sheets = (
    <>
      {contextMenuSheet}
      {ReportTemplateSelection}
    </>
  );

  return {
    element: sheets,
    open: openContextMenu,
  };
}
