import { nanoid } from "@kraaft/helper-functions";
import { offlineLogger } from "@kraaft/shared/core/utils/optimistic/newOptimistic/offline.logger";
import {
  BaseAggregate,
  Operation,
  OptimisticOperation,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";

import { trackEvent } from "../../../tracking/trackEvent";

export class OptimisticHelper {
  static log = offlineLogger.createSubLogger(["OptimisticHelper"]);

  static generateIds(count: number) {
    const ids: string[] = [];
    for (let i = 0; i < count; i += 1) {
      ids.push(nanoid());
    }
    return ids;
  }

  static isValidCreationResult(
    featureName: string,
    taskName: string,
    result: any,
  ) {
    if (
      !Array.isArray(result) ||
      !result.every((id: any) => typeof id === "string")
    ) {
      OptimisticHelper.log.error(
        `Task succeeded but did not return an array of string as ids for task ${taskName} (creations)`,
      );
      trackEvent({
        eventName: "OfflineFeature",
        feature: featureName,
        level: "error",
        log: "Not valid custom task mutate result (creations)",
        data: {
          taskName,
        },
      });
      return false;
    }
    return true;
  }

  static isValidCustomCreationResult(
    featureName: string,
    taskName: string,
    result: any,
  ) {
    if (
      !Array.isArray(result.ids) ||
      !result.ids.every((id: any) => typeof id === "string")
    ) {
      OptimisticHelper.log.error(
        `Task succeeded but did not return an array of string as ids for task ${taskName} (custom)`,
      );
      trackEvent({
        eventName: "OfflineFeature",
        feature: featureName,
        level: "error",
        log: "Not valid custom task mutate result (custom)",
        data: {
          taskName,
        },
      });
      return false;
    }
    return true;
  }

  static getOperationPayload<T extends Operation<any, any, any>["type"]>(
    type: T,
    payload: any,
  ): Awaited<
    ReturnType<Extract<Operation<any, object, any>, { type: T }>["mutate"]>
  > {
    return payload;
  }

  static getTargetIdsOfActionPayload(
    declaredOperation: Operation<any, any, any>,
    payload: any,
  ): string[] {
    if (declaredOperation.type === "creations") {
      if (declaredOperation.gatherExternalIds) {
        return [
          ...payload.ids,
          ...declaredOperation.gatherExternalIds(payload),
        ];
      }
      return payload.ids;
    }
    if (declaredOperation.type === "custom") {
      return declaredOperation.gatherIds(payload);
    }
    return [];
  }

  static shouldAcknowledgeOptimisticOperation(
    featureName: string,
    payload: Record<string, BaseAggregate>,
    declaredOperation: Operation<any, any, any>,
    operation: OptimisticOperation,
    result: any,
  ) {
    if (declaredOperation.type === "creations") {
      if (
        !OptimisticHelper.isValidCreationResult(
          featureName,
          operation.task.name,
          result,
        )
      ) {
        return true;
      }
      return result.some((id: string) => payload[id]);
    }
    if (declaredOperation.type === "custom") {
      if (!result) {
        return false;
      }
      return declaredOperation.shouldAcknowledge(
        payload,
        operation.task.payload,
        result,
      );
    }
  }
}
