import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { useNavigateToModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderDetails/useNavigateToModularFolderHistory";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  selectModularFolderHasAtLeastOneLockedColumn,
  selectModularFolderTitle,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { Guard } from "@kraaft/shared/core/services/auth/permissions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ActionSheet } from "@kraaft/ui";

import { AutoSaveInfoText } from "../autoSaveInfoText";

export interface ModularFolderContextMenuSheetProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  openReportTemplateSelection: () => void;
}

export const ModularFolderContextMenuSheet =
  ActionSheet().create<ModularFolderContextMenuSheetProps>(
    ({ ActionSheetItem, ActionSheetContent }) =>
      ({ openReportTemplateSelection, roomId, schemaId, modularFolderId }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const canDelete = Guard.use("ModularFolder.delete");

        const navigationService = useNavigationService();

        const schemaName = useSelector(selectSchemaName(schemaId));

        const hasAtLeastOneLockedColumn = useSelector(
          selectModularFolderHasAtLeastOneLockedColumn(modularFolderId),
        );

        const modularFolderTitle = useSelector(
          selectModularFolderTitle(modularFolderId),
        );

        const removeModularFolder = useCallback(() => {
          newConfirmationAlert({
            title: t("modularFolderDeleteAlertTitle.details"),
            confirmText: t("del"),
            onConfirm: () => {
              dispatch(
                OfflineModularFolderActions.delete({
                  id: modularFolderId,
                }),
              );
              if (isNative()) {
                navigationService.goBack();
              } else {
                navigationService.navigate("ModularFolders", {
                  roomId,
                  schemaId,
                });
              }
            },
          });
        }, [t, dispatch, modularFolderId, navigationService, roomId, schemaId]);

        const navigateToModularFolderHistory =
          useNavigateToModularFolderHistory({
            roomId,
            schemaId,
            modularFolderId,
          });

        const duplicate = useCallback(() => {
          const { payload } = dispatch(
            OfflineModularFolderActions.duplicate({
              modularFolderId,
            }),
          );
          const [firstId] = payload.ids;
          if (!firstId) {
            return;
          }
          navigationService.navigate("ModularFolderDetails", {
            roomId,
            schemaId,
            folderId: firstId,
          });
          trackEvent({
            eventName: "Duplicate Object Record",
            room_id: roomId,
            schema_id: schemaId,
            schema_name: schemaName ?? "",
            record_title: modularFolderTitle,
            from: "information_panel",
          });
        }, [
          dispatch,
          modularFolderId,
          modularFolderTitle,
          navigationService,
          roomId,
          schemaId,
          schemaName,
        ]);

        return (
          <ActionSheetContent title={<AutoSaveInfoText />}>
            <ActionSheetItem
              label={t("duplicate")}
              onClick={duplicate}
              icon="copy-06"
            />
            <ActionSheetItem
              label={
                isNative()
                  ? t("shareExportNative")
                  : t("generateFolderExportWeb")
              }
              onClick={openReportTemplateSelection}
              icon={isNative() ? "share-02" : "download-01"}
            />
            <ActionSheetItem
              label={t("history.seeHistory")}
              onClick={navigateToModularFolderHistory}
              icon="clock-rewind"
            />
            {!hasAtLeastOneLockedColumn && canDelete && (
              <ActionSheetItem
                label={t("del")}
                onClick={removeModularFolder}
                icon="trash-03"
                destructive
              />
            )}
          </ActionSheetContent>
        );
      },
  );
