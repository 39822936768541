import { offlineLogger } from "@kraaft/shared/core/utils/optimistic/newOptimistic/offline.logger";
import {
  BaseAggregate,
  type BuiltUserDeclaredOperations,
  OfflineFeature,
  UserDeclaredOperations,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { OptimisticHelper } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimisticHelper";
import { NamedTaskManager } from "@kraaft/shared/core/utils/optimistic/newOptimistic/taskStore/taskStore";

export function DeclareOfflineFeature<Aggregate extends BaseAggregate>(
  name: string,
) {
  const logger = offlineLogger.createSubLogger(["Root"]);

  return <
    const DeclaredOperations extends BuiltUserDeclaredOperations,
    Operations extends {
      [K in keyof DeclaredOperations]: ReturnType<
        DeclaredOperations[K]["getOperation"]
      >;
    },
  >(
    declaredOperations: DeclaredOperations,
    taskManager: NamedTaskManager,
  ): OfflineFeature<Aggregate, Operations> => {
    const operations = Object.entries(declaredOperations).reduce(
      (acc, [key, operationBuilder]) => {
        const operation = operationBuilder.getOperation();
        acc[key] = operation;
        taskManager.register(key, operation.mutate);
        return acc;
      },
      {} as { [key: string]: unknown },
    ) as Operations;

    taskManager.onTaskSucceeded.register(async (task, result) => {
      const declaredOperation = operations[task.name];
      if (!declaredOperation) {
        return;
      }
      let oldIds: string[] | undefined;
      let newIds: string[] | undefined;
      if (declaredOperation.type === "custom" && declaredOperation.creates) {
        if (
          !OptimisticHelper.isValidCustomCreationResult(name, task.name, result)
        ) {
          return;
        }
        oldIds = task.payload.allocatedIds;
        newIds = result.ids;
      }
      if (declaredOperation.type === "creations") {
        if (!OptimisticHelper.isValidCreationResult(name, task.name, result)) {
          return;
        }
        oldIds = task.payload.ids;
        newIds = result;
      }
      if (!oldIds || !newIds) {
        return;
      }
      await taskManager.edit((taskToEdit) => {
        const taskDeclaredOperation = operations[taskToEdit.name];
        if (!taskDeclaredOperation) {
          return;
        }
        for (let i = 0; i < oldIds.length; i += 1) {
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          const oldId = oldIds[i]!;
          const newId = newIds[i];
          if (!newId) {
            logger.error("Optimistically created more ids than persisted");
            return;
          }
          taskDeclaredOperation.replaceId(taskToEdit.payload, oldId, newId);
        }
      });
    });

    return {
      __aggregate: 0 as any,
      name,
      userDeclaredOperations: operations,
      taskManager,
    };
  };
}

/**
 * Simple method to extract taskManager from reduxBundle
 * This will allow us to import the whole reduxBundle in our
 */
export function getTaskManagerFromOfflineFeature(
  offlineReduxBundleList: Array<
    OfflineFeature<BaseAggregate, UserDeclaredOperations>
  >,
) {
  return offlineReduxBundleList.map((it) => it.taskManager);
}
