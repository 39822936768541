/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { AnyAction } from "redux";
import { put } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { updateNetworkState } from "@kraaft/shared/core/modules/app/appActions";
import {
  BrowserPath,
  ModernFileHelper,
} from "@kraaft/shared/core/modules/file/file";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { videoHelper } from "@kraaft/shared/core/modules/file/videoHelper/videoHelper.provider";
import {
  AudioMessage,
  BaseUserMessage,
  DocumentMessage,
  GeolocationMessage,
  ImageMessage,
  Message,
  TextMessage,
  VideoMessage,
} from "@kraaft/shared/core/modules/message/messageState";
import {
  DocumentOfflineMessage,
  ImageOfflineMessage,
  OfflineMessage,
  VideoOfflineMessage,
} from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import {
  errorSendingMessage,
  successSendingMessage,
} from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { RootState } from "@kraaft/shared/core/store";
import { getExtension } from "@kraaft/shared/core/utils";
import { Timings } from "@kraaft/shared/core/utils/tracking/timings";

function getOfflineMessageSize(offlineMessage: OfflineMessage) {
  switch (offlineMessage.type) {
    case "image":
    case "video":
    case "audio":
      return offlineMessage.file.size;
    default:
      return -1;
  }
}

export function createOptimisticMessage(
  offlineMessage: OfflineMessage,
): Message | undefined {
  const createdAt = offlineMessage.writtenAt
    ? new Date(offlineMessage.writtenAt)
    : new Date();
  const baseMessage: BaseUserMessage = {
    type:
      offlineMessage.type === "forwarded"
        ? offlineMessage.initialMessage.type
        : offlineMessage.type,
    id: offlineMessage.optimisticId,
    optimisticId: offlineMessage.optimisticId,
    senderId: offlineMessage.senderId,
    createdAt,
    updatedAt: createdAt,
    isReply: false,
    sendingStatus: offlineMessage.hasError ? "error" : "sending",
    ...(offlineMessage.type !== "forwarded"
      ? { answerTo: offlineMessage.answerTo }
      : {}),
    reactions: {},
  };

  switch (offlineMessage.type) {
    case "text": {
      const message: TextMessage = {
        ...baseMessage,
        type: "text",
        text: offlineMessage.text,
      };
      return message;
    }

    case "image": {
      const { width, height } = offlineMessage.size;
      const message: ImageMessage = {
        ...baseMessage,
        type: "image",
        attachment: {
          id: "optimistic",
          type: "image",
          createdAt: baseMessage.createdAt,
          senderUserId: baseMessage.senderId,
          caption: offlineMessage.caption,
          original: {
            filename: offlineMessage.file.filename,
            downloadUrl: offlineMessage.file.path,
          },
          size: { height, width },
          geolocation: undefined,
        },
      };
      return message;
    }

    case "audio": {
      const message: AudioMessage = {
        ...baseMessage,
        type: "audio",
        attachment: {
          id: "optimistic",
          type: "audio",
          createdAt: baseMessage.createdAt,
          senderUserId: baseMessage.senderId,
          original: {
            filename: offlineMessage.file.filename,
            downloadUrl: offlineMessage.file.path,
          },
        },
      };
      return message;
    }

    case "video": {
      const message: VideoMessage = {
        ...baseMessage,
        type: "video",
        attachment: {
          id: "optimistic",
          type: "video",
          createdAt: baseMessage.createdAt,
          senderUserId: baseMessage.senderId,
          caption: offlineMessage.caption,
          original: {
            filename: offlineMessage.file.filename,
            downloadUrl: offlineMessage.file.path,
          },
          converted: {},
          geolocation: undefined,
        },
      };
      return message;
    }

    case "document": {
      const message: DocumentMessage = {
        ...baseMessage,
        type: "document",
        attachment: {
          id: "optimistic",
          type: "document",
          createdAt: baseMessage.createdAt,
          senderUserId: baseMessage.senderId,
          original: {
            filename: offlineMessage.file.filename,
            downloadUrl: offlineMessage.file.path,
          },
        },
      };
      return message;
    }

    case "geolocation": {
      const message: GeolocationMessage = {
        ...baseMessage,
        type: "geolocation",
        geolocation: offlineMessage.geolocation,
        thumbnail: {
          // This is hacky, we use downloadUrl === "optimistic" somewhere to check if the download url is optimistic
          downloadUrl: "optimistic" as BrowserPath,
          filename: "static_map.jpg",
          size: undefined,
        },
      };
      return message;
    }
    case "forwarded": {
      const initialMessage = offlineMessage.initialMessage;

      baseMessage.forwarded = {
        by: initialMessage.senderId,
        from: offlineMessage.roomId,
      };

      if (initialMessage.type === "text") {
        const message: TextMessage = {
          ...baseMessage,
          type: "text",
          text: initialMessage.text,
        };
        return message;
      }
      if (initialMessage.type === "image") {
        const message: ImageMessage = {
          ...baseMessage,
          type: "image",
          attachment: initialMessage.attachment,
        };
        if (!offlineMessage.preserveCaptions) {
          message.attachment = {
            ...message.attachment,
            caption: undefined,
            captionModifiedAt: undefined,
          };
        }
        return message;
      }
      if (initialMessage.type === "audio") {
        const message: AudioMessage = {
          ...baseMessage,
          type: "audio",
          attachment: initialMessage.attachment,
        };
        return message;
      }
      if (initialMessage.type === "video") {
        const message: VideoMessage = {
          ...baseMessage,
          type: "video",
          attachment: initialMessage.attachment,
        };
        if (!offlineMessage.preserveCaptions) {
          message.attachment = {
            ...message.attachment,
            caption: undefined,
            captionModifiedAt: undefined,
          };
        }
        return message;
      }
      if (initialMessage.type === "document") {
        const message: DocumentMessage = {
          ...baseMessage,
          type: "document",
          attachment: initialMessage.attachment,
        };
        return message;
      }
      if (initialMessage.type === "geolocation") {
        const message: GeolocationMessage = {
          ...baseMessage,
          type: "geolocation",
          geolocation: initialMessage.geolocation,
          thumbnail: initialMessage.thumbnail,
        };
        return message;
      }
    }
  }
}

export function* notifySuccessSendingMessage(message: OfflineMessage) {
  yield* put(
    successSendingMessage({
      roomId: message.roomId,
      optimisticId: message.optimisticId,
    }),
  );
}

export function* notifyErrorSendingMessage(message: OfflineMessage) {
  yield* put(
    errorSendingMessage({
      roomId: message.roomId,
      optimisticId: message.optimisticId,
    }),
  );

  yield* put(
    showError({
      title: i18n.t("messageSendingErrorTitle"),
      message: i18n.t("messageSendingErrorBody"),
    }),
  );
}

export async function sendMessage(
  offlineMessage: OfflineMessage,
): Promise<void> {
  Timings.startSpan(["send_message", offlineMessage.optimisticId]);
  async function sendFileThreeSteps(
    message: ImageOfflineMessage | VideoOfflineMessage | DocumentOfflineMessage,
  ) {
    let processedFile = message.file;

    if (
      message.type === "video" &&
      getExtension(processedFile.filename) === "mov"
    ) {
      processedFile = ModernFileHelper.video(
        processedFile.filename,
        await videoHelper.convertBestEffortToMP4(
          processedFile.path,
          processedFile.filename,
        ),
      );
    }

    const { storagePath, uploadUrl } = await Api.createFileMessageUploadPath({
      roomId: message.roomId,
      filename: processedFile.filename ?? "",
      answerTo: message.answerTo,
    });

    await fileUpload.upload({
      file: processedFile,
      storagePath,
      uploadUrl,
    });

    await Api.addFileMessageAtomically({
      roomId: message.roomId,
      content: {
        filename: processedFile.filename,
        storagePath,
        optimisticId: message.optimisticId,
        ...(message.type !== "document"
          ? {
              caption: message.caption,
              coords: message.coords,
            }
          : { caption: undefined, coords: undefined }),
      },
      answerTo: message.answerTo,
    });
  }

  switch (offlineMessage.type) {
    case "text":
      await Api.addTextMessage({
        roomId: offlineMessage.roomId,
        text: offlineMessage.text,
        optimisticId: offlineMessage.optimisticId,
        answerTo: offlineMessage.answerTo,
      });
      break;
    case "image": {
      const processedFile = offlineMessage.file;

      const size =
        offlineMessage.file.size ??
        (await fileAllocator.size(processedFile.path));

      // If image size is > 1mo
      if (!size || size > 1024 * 1024) {
        return sendFileThreeSteps(offlineMessage);
      }

      await Api.sendOneshotImage({
        roomId: offlineMessage.roomId,
        optimisticId: offlineMessage.optimisticId,
        answerTo: offlineMessage.answerTo,
        content: {
          file: processedFile,
          caption: offlineMessage.caption,
          coords: offlineMessage.coords,
        },
      });
      break;
    }
    case "video":
    case "document":
      await sendFileThreeSteps(offlineMessage);
      break;
    case "audio": {
      const { storagePath, uploadUrl } = await Api.createFileMessageUploadPath({
        roomId: offlineMessage.roomId,
        filename: offlineMessage.file.filename || "",
        answerTo: offlineMessage.answerTo,
      });

      await fileUpload.upload({
        file: offlineMessage.file,
        storagePath,
        uploadUrl,
      });

      await Api.addAudioMessageAtomically({
        roomId: offlineMessage.roomId,
        content: {
          filename: offlineMessage.file.filename || "",
          storagePath,
          optimisticId: offlineMessage.optimisticId,
        },
        answerTo: offlineMessage.answerTo,
      });
      break;
    }
    case "geolocation":
      await Api.addGeolocationMessage({
        roomId: offlineMessage.roomId,
        geolocation: offlineMessage.geolocation,
        optimisticId: offlineMessage.optimisticId,
        answerTo: offlineMessage.answerTo,
      });
      break;
    case "forwarded":
      await Api.forwardMessageInRoom({
        roomId: offlineMessage.initialRoomId,
        messageId: offlineMessage.initialMessage.id,
        destRoomId: offlineMessage.roomId,
        optimisticId: offlineMessage.optimisticId,
        preserveCaptions: offlineMessage.preserveCaptions,
      });
      break;
  }
  Timings.stopSpan(["send_message", offlineMessage.optimisticId], {
    type: offlineMessage.type,
    size: getOfflineMessageSize(offlineMessage),
  });
}

export function waitBetterNetwork(action: AnyAction) {
  return updateNetworkState.match(action) && action.payload.hasImproved;
}

export function getSendingQueue(queue: OfflineMessage[]) {
  return queue.filter((item) => !item.hasError);
}

export const selectParams = (state: RootState) => ({
  currentUserId: state.user.userAuth?.uid,
  sendableQueue: getSendingQueue(state.offlineMessage.queue),
  queue: state.offlineMessage.queue,
  sentCount: state.offlineMessage.backgroundSessionSentCount,
  network: state.app.network,
});
