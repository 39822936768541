import { offlineLogger } from "@kraaft/shared/core/utils/optimistic/newOptimistic/offline.logger";
import {
  BaseAggregate,
  CreationOperation,
  CustomOperation,
  OptimisticOperation,
  UserDeclaredOperations,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { traceCloneDeep } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/traceCloneDeep";

import { trackEvent } from "../../../tracking/trackEvent";

export const OptimisticBuilder = {
  logger: offlineLogger.createSubLogger(["OptimisticBuilder"]),

  safelyCallExpectedForCreation(
    featureName: string,
    operationName: string,
    operation: CreationOperation<any, any, any, any>,
    payload: any,
  ) {
    try {
      return operation.expected({ ...payload, ...payload.augmented });
    } catch (e) {
      this.logger.warn(
        `Failed building optimistic with augmented payload for operation ${operationName}`,
      );
      trackEvent({
        eventName: "OfflineFeature",
        feature: featureName,
        level: "warn",
        log: "Failed building optimistic with augmented payload (creation)",
        data: {
          operationName,
        },
      });
      return operation.expected({ ...payload });
    }
  },

  safelyCallExpectedForCustom<Aggregate>(
    featureName: string,
    operationName: string,
    operation: CustomOperation<any, any, any, any, any>,
    datas: Record<string, Aggregate>,
    payload: any,
  ) {
    try {
      return operation.expected(datas, { ...payload, ...payload.augmented });
    } catch (e) {
      this.logger.warn(
        `Failed building optimistic with augmented payload for operation ${operationName}`,
      );
      trackEvent({
        eventName: "OfflineFeature",
        feature: featureName,
        level: "warn",
        log: "Failed building optimistic with augmented payload (custom)",
        data: {
          operationName,
        },
      });
      return operation.expected(datas, { ...payload });
    }
  },

  build<Aggregate extends BaseAggregate>(
    name: string,
    declaredOperations: UserDeclaredOperations,
    allOperations: OptimisticOperation[],
    allAggregates: Record<string, Aggregate>,
  ) {
    const createdAggregates: Record<string, Aggregate> = {};

    const finalState = { ...allAggregates };
    const cloned = new Set<string>();

    // Instead of cloning the whole state to make it mutable
    // It makes every entity mutable on the fly based on if it is accessed
    const proxy = new Proxy(finalState, {
      get(target, id) {
        if (typeof id !== "string") {
          return undefined;
        }
        if (cloned.has(id)) {
          return target[id];
        }
        const pristine = target[id];
        if (!pristine) {
          return undefined;
        }
        const writable = traceCloneDeep(`build ${id}`, pristine);
        target[id] = writable;
        cloned.add(id);
        return writable;
      },
      set(target, id, newValue) {
        if (typeof id !== "string") {
          return false;
        }
        target[id] = newValue;
        return true;
      },
      deleteProperty(target, id) {
        if (typeof id !== "string") {
          return false;
        }
        delete createdAggregates[id];
        delete target[id];
        return true;
      },
    });

    for (const operation of allOperations) {
      const declaredOperation = declaredOperations[operation.task.name];
      if (!declaredOperation) {
        this.logger.warn(
          `Cannot find declared operation for task name ${operation.task.name}`,
        );
        trackEvent({
          eventName: "OfflineFeature",
          feature: name,
          level: "warn",
          log: "Cannot find declared operation",
          data: {
            operationName: operation.task.name,
          },
        });
        continue;
      }
      if (declaredOperation.type === "creations") {
        const created = this.safelyCallExpectedForCreation(
          name,
          operation.task.name,
          declaredOperation,
          operation.task.payload,
        );
        for (let i = 0; i < created.length; i += 1) {
          const createdAggregate = created[i];
          const id = operation.task.payload.ids[i];

          proxy[id] = createdAggregate;
          createdAggregates[id] = createdAggregate;
          cloned.add(id);
        }
      } else if (declaredOperation.type === "custom") {
        this.safelyCallExpectedForCustom(
          name,
          operation.task.name,
          declaredOperation,
          proxy,
          operation.task.payload,
        );
      }
    }

    return { state: finalState, created: createdAggregates };
  },
};
