import { MarkRequired } from "ts-essentials";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { StorageFile } from "@kraaft/shared/core/modules/common/storageFile";
import { ImageSize } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { GeoLocation, GetDiscriminatedType } from "@kraaft/shared/core/types";
import { TrackTypeAttachSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

export interface StorageImagePreview extends StorageFile {
  size: ImageSize | undefined;
}

// Attachment Type

export type Attachment =
  | ImageAttachment
  | DocumentAttachment
  | VideoAttachment
  | AudioAttachment;

export type AttachmentSupportingGeoloc = VideoAttachment | ImageAttachment;

export const GEOLOCATED_ATTACHMENT_TYPES = ["video", "image"] as const;
export const ATTACHMENT_TYPES = [
  "video",
  "document",
  "image",
  "audio",
] as const;
export type AttachmentType = (typeof ATTACHMENT_TYPES)[number];

export interface AttachmentBase {
  /** @FIXME id is undefined in messages attachments */
  id: string;
  type: AttachmentType;
  original: StorageFile;
  createdAt: Date;

  messagePath?: string;
  senderUserId: string;
}

export interface DocumentAttachment extends AttachmentBase {
  type: "document";
}

export interface ImageAttachment extends AttachmentBase {
  type: "image";
  caption?: InputPartition[];
  captionModifiedAt?: Date;
  captionModifiedBy?: string;
  geolocation?: GeoLocation;
  size: ImageSize | undefined;
}

export interface VideoAttachment extends AttachmentBase {
  type: "video";
  caption?: InputPartition[];
  captionModifiedAt?: Date;
  captionModifiedBy?: string;
  geolocation?: GeoLocation;
  converted: {
    mp4?: StorageFile;
  };
}

export interface AudioAttachment extends AttachmentBase {
  type: "audio";
  transcription?: InputPartition[];
  transcriptionLocale?: string;
  transcriptionModifiedAt?: Date;
}

export type WithGPS<T extends AttachmentSupportingGeoloc> = MarkRequired<
  T,
  "geolocation"
>;

export type AttachmentWithGPS = WithGPS<AttachmentSupportingGeoloc>;

export type IndexedAttachmentWithGPS = AttachmentWithGPS & {
  originalIndex: number;
};

export type UploadAttachmentContext =
  | {
      type: "shareExtension";
      roomId: string;
      optimisticId: string;
    }
  | {
      type: "share";
      roomId: string;
      previousRoomId: string;
    }
  | {
      type: "room";
      roomId: string;
      answerTo?: string;
    }
  | {
      type: "modularFolder";
      roomId: string;
      schemaId: string;
      columnKey?: string;
      isPhotoQualityHD?: boolean;
      modularFolderId: string;
    }
  | {
      type: "directory";
      source: TrackTypeAttachSource;
      roomId: string;
      directoryId: string;
      loaderId: string;
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export type TypeDependentAttachmentProperties = DistributiveOmit<
  Attachment,
  keyof Omit<AttachmentBase, "type" | "original">
>;

export type TypeDependentAttachmentPropertiesWithType<
  T extends AttachmentType,
> = GetDiscriminatedType<TypeDependentAttachmentProperties, "type", T>;
