import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectOrderedEnabledTemplatesBySchemaId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { DownloadReportFormat } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";

import { FormatSelectionSheet } from "./formatSelection.sheet";
import { ReportTemplateSelectionSheet } from "./reportTemplateSelection.sheet";

interface UseReportTemplateSelectionProps {
  schemaId: string | undefined;
  allowPDF: boolean;
  callback: (
    reportTemplate: ReportTemplate,
    format: DownloadReportFormat,
  ) => void;
  anchorRef: React.RefObject<Element>;
}

export function useReportTemplateSelection(
  props: UseReportTemplateSelectionProps,
) {
  const { schemaId, allowPDF, callback, anchorRef } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const reportTemplates = useSelector(
    selectOrderedEnabledTemplatesBySchemaId(schemaId),
  );

  const { element: formatSelectionSheet, open: openFormatSelectionSheet } =
    FormatSelectionSheet.use({
      onFormatSelected: callback,
      anchor: anchorRef,
    });

  const handleTemplateSelected = useCallback(
    (template: ReportTemplate) => {
      if (allowPDF) {
        openFormatSelectionSheet({
          reportTemplate: template,
        });
      } else {
        callback(template, template.format);
      }
    },
    [allowPDF, callback, openFormatSelectionSheet],
  );

  const {
    element: reportTemplateSelectionSheet,
    open: openReportTemplateSheet,
  } = ReportTemplateSelectionSheet.use({
    schemaId,
    onTemplateSelected: handleTemplateSelected,
    anchor: anchorRef,
  });

  const handleReportTemplateOpen = useCallback(async () => {
    if (reportTemplates.length === 0) {
      dispatch(
        showError({
          title: "",
          message: t("reportTemplate.noTemplateAvailable"),
        }),
      );
    } else if (reportTemplates.length === 1 && reportTemplates[0]) {
      handleTemplateSelected(reportTemplates[0]);
    } else {
      openReportTemplateSheet();
    }
  }, [
    reportTemplates,
    dispatch,
    t,
    handleTemplateSelected,
    openReportTemplateSheet,
  ]);

  const sheets = (
    <>
      {reportTemplateSelectionSheet}
      {formatSelectionSheet}
    </>
  );

  return {
    openReportTemplateSelection: handleReportTemplateOpen,
    ReportTemplateSelection: sheets,
  };
}
