import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { Selector, SelectorArray, SelectorResultArray } from "reselect";

import {
  BaseAggregate,
  UserDeclaredOperations,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimistic.types";
import { OptimisticBuilder } from "@kraaft/shared/core/utils/optimistic/newOptimistic/optimistic/optimisticBuilder";
import { GlobalOfflineReduxBundle } from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.init";
import {
  ReduxSelectorsFromDeclaredOperations,
  UserProvidedSelectors,
} from "@kraaft/shared/core/utils/optimistic/newOptimistic/redux/reduxBundle.types";

export function CreateReduxBundleSelectors<
  Aggregate extends BaseAggregate,
  DeclaredOperations extends UserDeclaredOperations,
>(
  name: string,
  declaredOperations: DeclaredOperations,
  userProvidedSelectors: UserProvidedSelectors<Aggregate>,
) {
  const internalSelectors = {
    selectOptimistic: GlobalOfflineReduxBundle.createSelectorForFeature(name),
  };

  const selectBuilt = createSelector(
    userProvidedSelectors.selectRawAggregate,
    internalSelectors.selectOptimistic,
    (aggregates, optimistic) => {
      const built = OptimisticBuilder.build<Aggregate>(
        name,
        declaredOperations,
        optimistic.operations,
        aggregates,
      );
      return built;
    },
  );

  const selectAll = createSelector(selectBuilt, ({ state }) => {
    return state;
  });

  function createOfflineSelector<Selectors extends SelectorArray, Result>(
    selectorsArray: Selectors,
    combiner: (
      ...args: SelectorResultArray<
        [
          Selector<
            any,
            {
              state: Record<string, Aggregate>;
              created: Record<string, Aggregate>;
            },
            any
          >,
          ...Selectors,
        ]
      >
    ) => Result,
  ): Selector<any, Result> {
    return createSelector(
      [selectBuilt, ...selectorsArray] as any,
      combiner as any,
    );
  }

  const selectors: ReduxSelectorsFromDeclaredOperations<Aggregate> = {
    selectDetails: selectBuilt,
    selectAll,
    selectAllArray: createSelector(selectAll, (all) => Object.values(all)),
    select: memoize((id: string) => {
      return createSelector(
        selectAll,
        GlobalOfflineReduxBundle.selectIdCorrespondance,
        (all, correspondance) => {
          const found = all[id];
          if (found) {
            return found;
          }
          const replacedId = correspondance[id];
          if (!replacedId) {
            return undefined;
          }
          return all[replacedId];
        },
      );
    }),
    createSelector: createOfflineSelector as any,
  };

  return { selectors, internalSelectors };
}
